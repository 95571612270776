import { Card, Ratio } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function PersonTemplate({ details, index }) {

  const navigate = useNavigate();

  const handleClick = (id) => {   
    navigate('/activeOrdersList', {state:{id}});
  }


  return (
    <div className="col-lg-3 col-md-9 col-sm-9 col-9 mb-2" >
      <Card key={index} >
        <Ratio aspectRatio="4x3">
          <Card.Img variant="top" src={details.photo}
                    // onClick={() => handleClick(details.name)} 
          />
        </Ratio>
        <Card.Body>
          <Card.Title>{details.name}</Card.Title>
          <Card.Text>
            Designation: {details.designation}
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">+91 {details.contact}</small>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default PersonTemplate;
