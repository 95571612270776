import { useState } from "react";
import { Button, Container, Form, Row, Table } from "react-bootstrap";
import UnversalSpinner from "../CommonUtilites/UniversalSpinner";
import { useNavigate } from "react-router-dom";
import axiosAPIinstance from "../../config/axiosAPIinstance";
import BackButton from "../BackButton";

function CustomerOrderHistory(){

    const [loading, setLoading] = useState(false);
    const [enableSearch, setEnableSearch ] = useState(false);
    const [customerId, setCustomerId] = useState('');
    const [historyList, setHistoryList] = useState([]);
    const navigate = useNavigate();

    const handleHistorySearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try{
            const responseList = await axiosAPIinstance.get('/order/getCustomerOrderHistory', 
                                        { params: { 'customerId': customerId }, secure : true } );
        if(responseList.data){
            setHistoryList(responseList.data);
            setLoading(false);
        }
        }catch(error){
            console.log(error);
        }        
    }

    const updateCustomerId = (e) => {
        const value = e.target.value;
        // Allow only digits and ensure the length is not more than 10
        if (/^\d{0,10}$/.test(value)) {
          setCustomerId(value);
          if(value.length === 10){
            setEnableSearch(true);
          }else{
            setEnableSearch(false);
          }
        }
      };

      const viewOrderDetails = (orderId, customerId) => {
        navigate('/orderFullDetails', { state: { orderId, customerId } });
    }

    return(
        <>
            <Form className="login-container-frame" style={{height:'auto'}}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 10-digit mobile number"
                value={customerId}
                onChange={updateCustomerId}
                autoFocus
              />
              
              </Form.Group>
            </Form>
            <div className="divFlexSpan">  
                <BackButton />         
                <Button variant="primary" type="submit" onClick={handleHistorySearch} disabled={!enableSearch}>
                    Search
                </Button>
            <div className="divFlexSpan">
                { loading ? <UnversalSpinner /> 
                    : historyList.length !== 0 ? 
                    <>
                            <Container style={{marginTop:'30px'}}>
                                <Row className="d-grid gap-2">
                                    <div style={{ maxHeight: "90vh", overflowY: "auto", position: "relative" }}>
                                        <Table striped hover responsive className="w-100">
                                            <thead align="center" style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1000 }}>
                                                <tr>
                                                    <th><center>OrderID</center></th>
                                                    <th>OrderDate</th>
                                                    <th>CustomerID</th>
                                                    <th>Status</th>
                                                    <th>PaymentType</th>
                                                    <th>PaymentMode</th>
                                                    <th>Agent</th>
                                                </tr>
                                            </thead>
                                            <tbody align="center">
                                            { loading ? 
                                                <tr align="center"> 
                                                    <td colSpan={7}><UnversalSpinner /></td>
                                                </tr> 
                                                : <>
                                                {historyList.map((order) => (
                                                    <tr key={order.orderId}>
                                                        <td>
                                                            <Button variant="outline-success">ID=<strong>{order.orderId}</strong></Button>
                                                        </td>
                                                        <td>
                                                            <Button className="custom-margin" variant="outline-primary"><strong>{(order.orderCreatedTime.substring(0, 10)).replaceAll('-', '/')}</strong></Button>
                                                        </td>
                                                        <td>
                                                            <Button className="w-100 custom-margin" variant="success" onClick={() => viewOrderDetails(order.orderId, order.customerId)}>
                                                                {order.customerId}
                                                            </Button>
                                                        </td>
                                                        <td>
                                                            <Button className="w-100 custom-margin"
                                                                variant={order.orderStatus === 'Delivered' ? 'success' : (order.orderStatus === 'Canceled' ? 'outline-warning' : 'outline-primary')}
                                                                onClick={null}>
                                                                <strong>{order.orderStatus}</strong>
                                                            </Button>
                                                        </td>
                                                        <td>
                                                            <Button className="w-100 custom-margin" variant="outline-secondary">
                                                                {order.paymentMode == null ? 'Cash' : <strong>{order.paymentMode}</strong>}
                                                            </Button>
                                                        </td>
                                                        <td>
                                                            <Button className="w-100 custom-margin" variant="outline-secondary">
                                                                {order.paymentStatus == null ? 'Pending' : <strong>{order.paymentStatus}</strong>}
                                                            </Button>
                                                        </td>
                                                        <td>
                                                            <Button className="w-100 custom-margin" style={order.deliveryAgent ? {} : { color: 'red', fontWeight: 'bold' }} variant="outline-secondary">
                                                                {order.deliveryAgent == null ? 'UnAssigned' : <strong>{order.deliveryAgent}</strong>}
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                                
                                                </>
                                                }
                                                </tbody>                            
                                        </Table>
                                    </div>
                                </Row>
                            </Container>
                    </>
                    : <></> }
                </div>
            </div>

        </>
    );
}

export default CustomerOrderHistory;