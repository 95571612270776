import React from "react";
import { Table, Badge } from "react-bootstrap";

const NvDataTable = ({ headers, data, onRowClick, onRowHover }) => {
    const formatValue = (value) => {
        if (Array.isArray(value)) {
            return (
                <div>
                    {value.map((item, index) => (
                        <Badge
                            key={index}
                            bg="info"
                            className="d-block mb-1"
                            style={{ fontSize: "0.9em" }}
                        >
                            {item.productName} (Qty: {item.productOrderedQuantity})
                        </Badge>
                    ))}
                </div>
            );
        }
        if (typeof value === "object" && value !== null) {
            return (
                <div style={{ fontStyle: "italic", color: "gray" }}>
                    {JSON.stringify(value)}
                </div>
            );
        }
        return value;
    };

    return (
        <div
            style={{
                maxHeight: "75vh",
                overflowY: "auto",
                position: "relative",
                border: "1px solid #dee2e6",
                borderRadius: "8px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
            }}
        >
            <Table striped hover responsive className="w-100">
                <thead
                    align="center"
                    style={{
                        position: "sticky",
                        top: 0, // Adjust the offset as needed
                        backgroundColor: "#007bff",
                        color: "white",
                        zIndex: 1000,
                        fontWeight: "bold",
                        boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                    }}
                >
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index} style={{ padding: "12px", fontSize: "1em" }}>
                                <center>{header}</center>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody align="center">
                    {data.length > 0 ? (
                        data.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                onClick={() => onRowClick && onRowClick(row)}
                                style={{
                                    cursor: "pointer",
                                    transition: "background-color 0.2s ease",
                                }}
                                onMouseEnter={(e) => {
                                    (e.currentTarget.style.backgroundColor = "#f8f9fa");
                                    onRowHover && onRowHover(row);
                                }
                                }
                                onMouseLeave={(e) => {
                                    (e.currentTarget.style.backgroundColor = "");
                                    onRowHover && onRowHover(null);
                                }
                                }
                            >
                                {Object.entries(row).map(([key, value], colIndex) => (
                                    <td key={colIndex} style={{ padding: "12px", fontSize: "0.95em" }}>
                                        {key !== "orderDetails" ? formatValue(value) : null}
                                    </td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr align="center">
                            <td colSpan={headers.length} style={{ padding: "20px" }}>
                                <div style={{ fontStyle: "italic", color: "gray" }}>
                                    No data available
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default NvDataTable;
