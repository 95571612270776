import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [dbUser, setDbUser] = useState({});
    const [altMobileNumber, setAltMobileNumber] = useState('');
    const [altShippingAddress, setAltShippingAddress] = useState('');
    const [deviceGpsLocation, setDeviceGpsLocation] = useState(null);

    useEffect(() => {
        const initializeUserData = () => {
            try {
                const isAuthenticated = localStorage.getItem('isAuthenticated');
                const token = localStorage.getItem('jwtAuthToken');
                const storedMobileNumber = localStorage.getItem('mobileNumber');
                const dbUser = localStorage.getItem('dbUser');
                const altShippingAddress = localStorage.getItem('altShippingAddress');
                const deviceGpsLocation = localStorage.getItem('deviceGpsLocation');
                const altMobileNumber = localStorage.getItem('altMobileNumber');

                setIsAuthenticated(isAuthenticated ? JSON.parse(isAuthenticated) : false);
                setToken(token || '');
                setMobileNumber(storedMobileNumber || '');
                setDbUser(dbUser ? JSON.parse(dbUser) : {});
                setAltMobileNumber(altMobileNumber || '');
                setAltShippingAddress(altShippingAddress || '');
                setDeviceGpsLocation(deviceGpsLocation ? JSON.parse(deviceGpsLocation) : null);
            } catch (error) {
                console.error('Failed to initialize user data:', error);
            }
        };

        initializeUserData();
    }, [isAuthenticated]);

    const login = (token, user, isAuthenticated, mobileNumber) => {
        try {
            localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
            const jwtToken = token.startsWith('Bearer ') ? token.substring(7) : token;
            localStorage.setItem('jwtAuthToken', jwtToken);
            localStorage.setItem('mobileNumber', mobileNumber);
            localStorage.setItem('dbUser', JSON.stringify(user));
            setToken(jwtToken);
            setMobileNumber(mobileNumber);
            setIsAuthenticated(isAuthenticated);
            setDbUser(user);
        } catch (error) {
            console.error('Login error:', error);
        }
    };

    const logout = () => {
        try {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('jwtAuthToken');
            localStorage.removeItem('mobileNumber');
            localStorage.removeItem('dbUser');
            localStorage.removeItem('orderHistory');
            localStorage.removeItem('altMobileNumber');
            localStorage.removeItem('altShippingAddress');
            localStorage.removeItem('deviceGpsLocation');
            setIsAuthenticated(false);
            setToken('');
            setMobileNumber('');
            setDbUser({});
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    const saveUserData = (altMobileNumber, altShippingAddress, deviceGpsLocation) => {
        try {
            localStorage.setItem('altMobileNumber', altMobileNumber);
            localStorage.setItem('altShippingAddress', altShippingAddress);
            localStorage.setItem('deviceGpsLocation', JSON.stringify(deviceGpsLocation));
            setAltMobileNumber(altMobileNumber);
            setAltShippingAddress(altShippingAddress);
            setDeviceGpsLocation(deviceGpsLocation);
        } catch (error) {
            console.error('Error saving user data:', error);
        }
    };

    return (
        <UserContext.Provider
            value={{
                isAuthenticated,
                token,
                mobileNumber,
                dbUser,
                altMobileNumber,
                altShippingAddress,
                deviceGpsLocation,
                setDeviceGpsLocation,
                saveUserData,
                login,
                logout,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
