import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import axiosAPIinstance from '../../config/axiosAPIinstance';

const PromoCodeList = () => {

  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [currentPromoCode, setCurrentPromoCode] = useState(null);

  useEffect(() => {
    setLoading(true);
    axiosAPIinstance
      .get('/offers/getAllCodes', {secure : true})
      .then((response) => {
        setPromoCodes(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching promo codes:', error);
        setLoading(false);
      });
  }, []);

  const handleEdit = (promoCode) => {
    setCurrentPromoCode(promoCode);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentPromoCode(null);
  };

  const handleSave = () => {
    // Make PUT request to update the promo code
    axiosAPIinstance
      .put('/offers/updatePromoCode', currentPromoCode, {secure : true})
      .then((response) => {
        setResponseMessage(response.data);
        
        // Update the promoCodes state with the updated promo code
        setPromoCodes(prevPromoCodes => 
          prevPromoCodes.map(promo => promo.id === currentPromoCode.id ? currentPromoCode : promo)
        );
        
        handleClose();
      })
      .catch((error) => {
        console.error('Error updating promo code:', error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentPromoCode({
      ...currentPromoCode,
      [name]: value,
    });
  };

  if (loading) {
    return <div style={{ margin: '2rem 20rem', fontSize: '24px', fontWeight: 'bold' }}><CircularProgress /></div>;
  }

  return (
    <>
      <div style={{ margin: '2rem 20rem' }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Promo Code</TableCell>
                <TableCell>Discount Value</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Expiration Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {promoCodes.map((promoCode) => (
                <TableRow key={promoCode.id}>
                  <TableCell>{promoCode.code}</TableCell>
                  <TableCell>{promoCode.discountValue}</TableCell>
                  <TableCell>{promoCode.active ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{promoCode.expirationDate}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEdit(promoCode)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {responseMessage.length > 0 && <h4>{responseMessage}</h4>}
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Promo Code</DialogTitle>
        <DialogContent>
          {currentPromoCode && (
            <div style={{ margin: '1rem' }}>
              <form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Promo Code"
                      name="code"
                      value={currentPromoCode.code}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Discount Value"
                      name="discountValue"
                      type="number"
                      value={currentPromoCode.discountValue}
                      onChange={handleChange}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Expiration Date"
                      name="expirationDate"
                      type="datetime-local"
                      value={currentPromoCode.expirationDate}
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={currentPromoCode.active}
                          onChange={(e) => handleChange({ target: { name: 'active', value: e.target.checked } })}
                          name="active"
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PromoCodeList;
