import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Typography
} from '@mui/material';
import { Edit, Visibility } from '@mui/icons-material';
import BackButton from '../BackButton';
import OfferDetails from '../UpdateServices/Update-Offers';
import axiosAPIinstance from '../../config/axiosAPIinstance';

const OffersList = () => {

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setIsEditMode(false);
  };

  const handleEditItem = (item) => {
    setSelectedItem(item);
    setIsEditMode(true);
  };

  const handleCloseDetails = () => {
    setSelectedItem(null);
  };

  const fetchOrders = () => {
    axiosAPIinstance.get('/offers/getOffersList', {secure : true})
    .then(response => setItems(response.data))
    .catch(error => console.error('Error fetching items:', error));
  }

  return (
    <div style={{ margin: '3rem' }}>
      <Container>
        <Typography variant="h4" gutterBottom>
          Offers List
        </Typography>
        <List>
          {items.map((item) => (
            <ListItem key={item.itemId} divider>
              <ListItemAvatar>
                <img
                  src={item.image}
                  alt={item.offerTitle}
                  style={{
                    width: '100px',
                    height: '60px',
                    marginRight: '1rem',
                    borderRadius: '4px',
                    border: '2px solid gray',
                    objectFit: 'cover',
                  }}
                />            </ListItemAvatar>
              <ListItemText>{item.offerTitle}{item.active ? <span style={{ fontWeight: 'bold' }}> - Active </span> : ''}</ListItemText>
              <IconButton onClick={() => handleViewDetails(item)}>
                <Visibility />
              </IconButton>
              <IconButton onClick={() => handleEditItem(item)}>
                <Edit />
              </IconButton>
            </ListItem>
          ))}
        </List>

        {selectedItem && (
          <OfferDetails
            item={selectedItem}
            isEditMode={isEditMode}
            onClose={handleCloseDetails}
            onSave={() => {
              fetchOrders();
              handleCloseDetails();
            }}
          />
        )}
      </Container>

      <BackButton />

    </div>
  );
};

export default OffersList;