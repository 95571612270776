import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  IconButton
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import UnversalSpinner from '../CommonUtilites/UniversalSpinner';
import { format } from 'date-fns';
import { RemoveCircleOutline } from '@mui/icons-material';
import axiosAPIinstance from '../../config/axiosAPIinstance';
import { useLocation, useNavigate } from 'react-router-dom';

const weightOptions = ['100g', '250g', '500g', '1kg', '2kg', '5kg', '10kg', '25kg', '100ml', '250ml', '500ml', '1L', '2L', '5L', '10L', '25L', 'Enter Manually'];

const ViewUpdateItemDetails = ({ item, isEditMode, onClose, onSave }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [editableItem, setEditableItem] = useState({ ...item });
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [responseData, setResponseData] = useState('');
  const [errors, setErrors] = useState({});
  const [response, setResponse] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
  const [imgError, setImgError] = useState(false);
  const [dynamicWeightOptions, setDynamicWeightOptions] = useState(weightOptions);


  const [weights, setWeights] = useState(item.productVariants);
  console.log('weights from db are ', item.productVariants)
  // const formatFloat = (value) => { return parseFloat(value).toFixed(2); };
  const formatFloat = (value) => {
    return isNaN(value) ? '0.00' : parseFloat(value).toFixed(2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableItem((prevState) => ({ ...prevState, [name]: value }));
    console.log("handleInputChange", name, value)
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setEditableItem((prevState) => ({ ...prevState, image: file }));
    setImagePreview(URL.createObjectURL(file));
    console.log("handleFileChange", e.target.files[0])
    setImgError(true);
  };

  const handleAdditionalFilesChange = (e) => {
    const files = Array.from(e.target.files);
    setEditableItem((prevState) => ({ ...prevState, additionalImages: files }));
    setAdditionalImagePreviews(files.map((file) => URL.createObjectURL(file)));
    console.log("handleAdditionalFilesChange", e.target.files)
    setImgError(true);
  };

  const handleWeightChange = (index, field, value) => {
    const updatedWeights = [...weights];
    // updatedWeights[index][field] = value;

    if (field === 'discountPercent') {
      const mrp = updatedWeights[index].mrp;
      const discountPercent = value;
      const sellingPrice = mrp - (mrp * discountPercent * 0.01);
      updatedWeights[index].sellingPrice = formatFloat(sellingPrice);
    }

    if (field === 'gstPercent') {
      const gstPercent = value;
      const sellingPrice = updatedWeights[index].sellingPrice || updatedWeights[index].mrp;
      const calculatedGST = sellingPrice * gstPercent * 0.01;
      updatedWeights[index].gstPrice = formatFloat(calculatedGST);
    }

    if (field === 'weight') {
      updatedWeights[index].weight = value;
      if (value !== 'Enter Manually' && !dynamicWeightOptions.includes(value)) {
        setDynamicWeightOptions([value, ...dynamicWeightOptions]);
      }
    } else {
      updatedWeights[index][field] = value;
    }

    setWeights(updatedWeights);
    console.log("handleWeightChange", updatedWeights);
  };


  const addWeightField = () => {
    setWeights([
      ...weights,
      {
        weight: '',
        mrp: '',
        costPrice: '',
        sellingPrice: '',
        discountPercent: '',
        gstPercent: '',
        gstPrice: '',
        sku: '',
        availableStock: '',
        minPurchaseQty: '',
        dimensions: '',
        availabilityStatus: '',
        returnable: ''
      },
    ]);
  };

  const removeWeightField = (index) => {
    setWeights(weights.filter((_, i) => i !== index));
  };

  const handleDateChange = (name, date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setEditableItem((prevState) => ({ ...prevState, [name]: formattedDate }));
    console.log('Date changed to ', formattedDate);
    console.log('editable item', editableItem);

  };

  const handleSave = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // Append fields from the `item` object
    Object.keys(editableItem).forEach((key) => {
      if (editableItem[key] instanceof File) {
        // Append the file (e.g., `image`)
        formData.append(key, editableItem[key]);
      } else if (Array.isArray(editableItem[key])) {
        // Append additional images
        editableItem[key].forEach((file) => {
          if (file instanceof File) {
            formData.append(key, file);
          } else {
            formData.append(key, JSON.stringify(file));
          }
        });
      } else {
        // Append other fields as string values
        formData.append(key, editableItem[key]);
        console.log('other elements are ', key, editableItem[key])
      }
    });

    // Append `weights` (product variants) as a JSON string
    formData.append('productVarients', JSON.stringify(weights));

    try {
      setLoading(true);
      const response = await axiosAPIinstance.patch(`/product/updateProductDetails/${item.productId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        secure: true
      });
      setResponse(response.data);
      setLoading(false);
      setOpenModal(true);
      setEditableItem({
        productName: '',
        barCode: '',
        brand: '',
        category: '',
        description: '',
        warehouseLocation: '',
        image: null,
        additionalImages: [],
        purchaseDate: format(new Date(), "yyyy-MM-dd"),
        expiryDate: format(new Date(), "yyyy-MM-dd"),
      });
      setWeights([
        {
          weight: '',
          mrp: '',
          costPrice: '',
          sellingPrice: '',
          discountPercent: '',
          gstPercent: '',
          gstPrice: '',
          sku: '',
          availableStock: '',
          minPurchaseQty: '',
          dimensions: '',
          availabilityStatus: '',
          returnable: ''
        },
      ]);
      setImagePreview('');
      setAdditionalImagePreviews([]);
    } catch (error) {
      console.error('Error adding item:', error);
      setResponse('Error adding item. Please try again.');
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    onSave(responseData);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '10px' }}>
          <UnversalSpinner />
        </div>
      ) : (
        <>
          <Dialog open={Boolean(item)} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{isEditMode ? 'Edit Product Details' : 'View Product Details'}</DialogTitle>
            <DialogContent dividers>
              <form onSubmit={handleSave}>
                <Grid container spacing={2} margin={1} width={'95%'}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="productName"
                      label="Product Name"
                      fullWidth
                      required
                      value={editableItem.productName}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="barCode"
                      label="Barcode"
                      fullWidth
                      value={editableItem.barCode}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="brand"
                      label="Brand"
                      fullWidth
                      required
                      value={editableItem.brand}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="category"
                      label="Category"
                      fullWidth
                      value={editableItem.category}
                      onChange={handleInputChange}
                      disabled={!isEditMode}
                    />
                  </Grid>

                  {weights.map((weight, index) => (
                    <Grid container spacing={2} margin={1} padding={1} key={index} style={{ border: '2px solid lightgray', borderRadius: '10px' }}>
                      <Grid item xs={12} sm={2}>
                        <FormControl fullWidth required>
                          <InputLabel>Weight</InputLabel>
                          <Select
                            value={weight.weight}
                            disabled={!isEditMode}
                            onChange={(e) => handleWeightChange(index, 'weight', e.target.value)}
                          >
                            {[weight.weight, ...dynamicWeightOptions].map((option) => (
                              <MenuItem key={option} value={option}>
                                <ListItemText primary={option} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {weight.weight === 'Enter Manually' && (
                        <Grid item xs={12} sm={2}>
                          <TextField
                            label="Enter"
                            placeholder=''
                            fullWidth
                            required
                            onBlur={(e) => handleWeightChange(index, 'weight', e.target.value)}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Cost Price"
                          type="number"
                          fullWidth
                          required
                          disabled={!isEditMode}
                          value={weight.costPrice}
                          onChange={(e) => handleWeightChange(index, 'costPrice', e.target.value)}
                          onBlur={(e) => handleWeightChange(index, 'costPrice', formatFloat(e.target.value))}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="MRP"
                          type="number"
                          fullWidth
                          required
                          disabled={!isEditMode}
                          value={weight.mrp}
                          onChange={(e) => handleWeightChange(index, 'mrp', e.target.value)}
                          onBlur={(e) => handleWeightChange(index, 'mrp', formatFloat(e.target.value))}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Discount %"
                          type="number"
                          fullWidth
                          required
                          disabled={!isEditMode}
                          value={weight.discountPercent}
                          onChange={(e) => handleWeightChange(index, 'discountPercent', e.target.value)}
                          onBlur={(e) => handleWeightChange(index, 'discountPercent', formatFloat(e.target.value))}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Selling Price"
                          type="number"
                          fullWidth
                          disabled
                          value={weight.sellingPrice}
                          onChange={(e) => handleWeightChange(index, 'sellingPrice', e.target.value)}
                          onBlur={(e) => handleWeightChange(index, 'sellingPrice', formatFloat(e.target.value))}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="GST %"
                          type="number"
                          fullWidth
                          required
                          disabled={!isEditMode}
                          value={weight.gstPercent}
                          onChange={(e) => handleWeightChange(index, 'gstPercent', e.target.value)}
                          onBlur={(e) => handleWeightChange(index, 'gstPercent', formatFloat(e.target.value))}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="GST Value"
                          type="number"
                          fullWidth
                          disabled
                          value={weight.gstPrice}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Pair Of"
                          type="number"
                          fullWidth
                          required
                          disabled={!isEditMode}
                          value={weight.minPurchaseQty}
                          onChange={(e) => handleWeightChange(index, 'minPurchaseQty', e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Available Stock"
                          type="number"
                          fullWidth
                          required
                          disabled={!isEditMode}
                          value={weight.availableStock}
                          onChange={(e) => handleWeightChange(index, 'availableStock', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="Dimensions"
                          fullWidth
                          disabled={!isEditMode}
                          value={weight.dimensions}
                          onChange={(e) => handleWeightChange(index, 'dimensions', e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <TextField
                          label="sku Barcode"
                          fullWidth
                          disabled={!isEditMode}
                          value={weight.sku}
                          onChange={(e) => handleWeightChange(index, 'sku', e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <FormControl fullWidth required>
                          <InputLabel id="availability-status-label">Availability Status</InputLabel>
                          <Select
                            value={weight.availabilityStatus}
                            disabled={!isEditMode}
                            onChange={(e) => handleWeightChange(index, 'availabilityStatus', e.target.value)}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <FormControl fullWidth required>
                          <InputLabel id="returnable-status-label">Is Returnable</InputLabel>
                          <Select
                            value={weight.returnable}
                            disabled={!isEditMode}
                            onChange={(e) => handleWeightChange(index, 'returnable', e.target.value)}
                          >
                            <MenuItem value={true}>Yes</MenuItem>
                            <MenuItem value={false}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={2}>
                        <IconButton disabled={!isEditMode} onClick={() => removeWeightField(index)} color="error">
                          <RemoveCircleOutline />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid item xs={12} sm={3}>
                    <Button onClick={addWeightField} variant="outlined" fullWidth disabled={!isEditMode}>
                      Add More
                    </Button>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="numberOfReviews"
                      label="Number of Reviews"
                      type="number"
                      fullWidth
                      disabled={!isEditMode}
                      value={editableItem.numberOfReviews}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="manufacturer"
                      label="Manufacturer"
                      fullWidth
                      disabled={!isEditMode}
                      value={editableItem.manufacturer}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="modelNumber"
                      label="Model Number"
                      fullWidth
                      disabled={!isEditMode}
                      value={editableItem.modelNumber}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="warrantyInfo"
                      label="Warranty Info"
                      fullWidth
                      disabled={!isEditMode}
                      value={editableItem.warrantyInfo}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="returnPolicy"
                      label="Return Policy"
                      fullWidth
                      disabled={!isEditMode}
                      value={editableItem.returnPolicy}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="tags"
                      label="Tags"
                      fullWidth
                      disabled={!isEditMode}
                      value={editableItem.tags}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Purchase Date"
                        value={editableItem.purchaseDate}
                        required
                        disabled={!isEditMode}
                        onChange={(date) => handleDateChange('purchaseDate', date)}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Expiry Date"
                        value={editableItem.expiryDate}
                        required
                        disabled={!isEditMode}
                        onChange={(date) => handleDateChange('expiryDate', date)}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="description"
                      label="Description"
                      multiline
                      rows={4}
                      fullWidth
                      disabled={!isEditMode}
                      value={editableItem.description}
                      onChange={handleInputChange}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <Button variant="contained" component="label" disabled={!isEditMode} fullWidth style={{ backgroundColor: imgError && editableItem.image ? '' : 'red' }}>
                      Upload Image
                      <input type="file" hidden name="image" onChange={handleFileChange} required />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <img
                      src={imagePreview !== '' ? URL.createObjectURL(editableItem.image) : editableItem.image}
                      alt="Preview"
                      disabled={!isEditMode}
                      style={{ width: '250px', maxHeight: '250px', objectFit: 'cover', borderRadius: '8px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="contained" component="label" disabled={!isEditMode} fullWidth style={{ backgroundColor: imgError && editableItem.additionalImages.length > 0 ? '' : 'red' }}>
                      Upload Additional Images
                      <input type="file" hidden multiple name="additionalImages" required onChange={handleAdditionalFilesChange} />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {editableItem.additionalImages.map((image, index) => (
                        <img
                          key={index}
                          src={additionalImagePreviews.length !== 0 ? URL.createObjectURL(image) : image}
                          disabled={!isEditMode}
                          alt="Additional Preview"
                          style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                        />
                      ))}

                    </div>

                  </Grid>
                </Grid>

              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Cancel
              </Button>
              {isEditMode && (
                <Button onClick={handleSave} color="primary" variant="contained">
                  {loading && <UnversalSpinner />}
                  Save
                </Button>
              )}
            </DialogActions>
          </Dialog>

          {/* Modal to display response */}
          <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogContent>
              <Typography variant="h6" align="center" color="textSecondary">
                Update Completed
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
      }
    </>

  );
};

export default ViewUpdateItemDetails;
