import { Button, Box, TextField, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import BackButton from "../BackButton";
import GPStracker from "../CommonUtilites/GPStracker";
import UniversalSpinner from "../CommonUtilites/UniversalSpinner";
import { format } from "date-fns";
import axiosAPIinstance from "../../config/axiosAPIinstance";

function AddNewCustomer() {
    const [response, setResponse] = useState('');
    const [deviceLocation, setDeviceLocation] = useState('');
    const [idLength, setIdLength] = useState(true);
    const [editable, setEditable] = useState(false);
    const [customer, setCustomer] = useState({
        customerId: '',
        name: '',
        contact: '',
        emailId: '',
        address: '',
        gender: '',
        photo: '',
        dateOfBirth: null,
        zone: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomer({ ...customer, [name]: value });
    };

    const handleDateChange = (date) => {
        // Convert to ISO format expected by LocalDateTime
        const formattedDate = date ? format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX") : null;
        setCustomer({ ...customer, dateOfBirth: formattedDate });
    };

    const handleFileChange = (e) => {
        setCustomer({ ...customer, photo: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (customer.customerId.length === 10) {
            try {
                const formData = new FormData();
                for (const key in customer) {
                    if (customer[key]) {
                        formData.append(key, customer[key]);
                    }
                }
                formData.append('gpsLocation', deviceLocation);
                if (customer.photo) {
                    formData.append('photo', customer.photo);
                }
    
                setEditable(true);
                const response = await axiosAPIinstance.post('/customer/newCustomer', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                setIdLength(true);
                setResponse(response.data);
            } catch (error) {
                console.error('Error during form submission:', error);
            } finally {
                setEditable(false);
            }
        } else {
            setIdLength(false);
        }
    };    

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 500, mx: "auto", p: 2 }}>
            <Typography variant="h6" gutterBottom>
                <b>Add New Customer</b>
            </Typography>

            <TextField
                label="Customer ID"
                name="customerId"
                placeholder="Customer ID"
                value={customer.customerId}
                onChange={handleInputChange}
                required
                fullWidth
                error={!idLength}
                helperText={!idLength && "Please enter 10 digits"}
                margin="normal"
            />

            <TextField
                label="Name"
                name="name"
                placeholder="Name"
                value={customer.name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />

            <TextField
                label="Alt Contact"
                name="contact"
                placeholder="Alt Contact"
                value={customer.contact}
                onChange={handleInputChange}
                required
                fullWidth
                margin="normal"
            />

            <TextField
                label="Zone"
                name="zone"
                placeholder="Zone"
                value={customer.zone}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />

            <TextField
                label="Email ID"
                name="emailId"
                placeholder="Email ID"
                value={customer.emailId}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
            />

            <FormControl component="fieldset" margin="normal">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                    row
                    name="gender"
                    value={customer.gender}
                    onChange={(e) => setCustomer({ ...customer, gender: e.target.value })}
                >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                </RadioGroup>
            </FormControl>

            <TextField
                label="Full Address"
                name="address"
                placeholder="Full Address"
                value={customer.address}
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={3}
                margin="normal"
            />

            <Typography variant="body1" gutterBottom>
                Photo/Image [Max 10MB]
            </Typography>
            <input
                type="file"
                name="photo"
                accept="image/*"
                required
                onChange={handleFileChange}
                aria-label="Upload Image"
                style={{ marginBottom: "16px" }}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    label="Date of Birth"
                    value={customer.dateOfBirth}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
            </LocalizationProvider>

            <Typography variant="body2" color="textSecondary" gutterBottom>
                User Location: {deviceLocation}
            </Typography>

            {response && (
                <Typography color="green" sx={{ mt: 2 }}>
                    <b>{response}</b>
                </Typography>
            )}
            {editable && <UniversalSpinner />}

            <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={editable}
                fullWidth
                sx={{ mt: 2 }}
            >
                Add
            </Button>
            
            <BackButton />
            <GPStracker setDeviceLocation={setDeviceLocation} />
        </Box>
    );
}

export default AddNewCustomer;
