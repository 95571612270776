import { useContext, useEffect } from 'react';
import LoginForm from './Login_Form';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';

function LogInCheck() {

  const { isAuthenticated } = useContext(UserContext);

  useEffect(() => {
    console.log("Refreshing...");
  }, [isAuthenticated])

  return (
    <>
      {!isAuthenticated ?
        <LoginForm />
        :
        <Navigate to='/' />
      }
    </>
  );
}

export default LogInCheck;
