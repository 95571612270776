import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Layout from './Application_pages/Layout';
import NotFound from './Application_pages/CommonSharedFiles/NotFound';
import Home from './Application_pages/LogInPage/Home';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LogInCheck from './Application_pages/LogInCheck';
import AddItem from './Application_pages/AddItems';
import CreateAccount from './Application_pages/CreateAccount';
// import AddToCart from './Application_pages/AddToCart';
import ActiveOrdersList from './Application_pages/ActiveOrdersList.js';
import OrderFullDetails from './Application_pages/OrderFullDetails';
import ContactUs from './Application_pages/ContactUs/ContactUs';
import AdminServices from './Application_pages/AdminServices/AdminOverview.js';
import AboutUs from './Application_pages/AboutUs/AboutUs';
import AddNewAgent from './Application_pages/AgentService/AddNewAgent.js';
import ActiveAgents from './Application_pages/AgentService/ActiveAgents.js';
import AllAgentsList from './Application_pages/AgentService/AllAgentsList.js';
import TotalOrdersList from './Application_pages/TotalOrdersList.js';
import PrivateSecureRoute from './Application_pages/Security/PrivateSecureRoute.js';
import { AuthProvider } from './Application_pages/Security/AuthContext.js';
import AddNewCustomer from './Application_pages/CustomerService/NewCustomer.js';
import AdminNewServices from './Application_pages/AdminServices/AdminNewServices.js';
import AdminActiveServices from './Application_pages/AdminServices/AdminActiveServices.js';
import AdminDetailServices from './Application_pages/AdminServices/AdminDetailServices.js';
import AllCustomersList from './Application_pages/CustomerService/AllCustomersList.js';
import CustomerFullDetails from './Application_pages/CustomerService/CustomerFullDetails.js';
import CustomerOrderHistory from './Application_pages/CustomerService/CustomerOrderHistory.jsx';
import ViewAllItems from './Application_pages/AdminServices/viewAll-items.jsx';
import OffersScreen from './Application_pages/NewServices/OffersScreen.jsx';
import OfferDetails from './Application_pages/UpdateServices/Update-Offers.jsx';
import OffersList from './Application_pages/AdminServices/OffersList.jsx';
import ReturnOrdersList from './Application_pages/Orders/ReturnOrders.jsx';
import PromoCodeForm from './Application_pages/PromoCodes/PromoCodeForm.jsx';
import PromoCodeList from './Application_pages/PromoCodes/PromoCodeList.jsx';
import { AxiosInterceptorProvider } from './config/axiosAPIinstance.js';
import { UserProvider } from './context/UserContext.jsx';

// Configure the router with routes and their components
const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <div className='main-container'>
            <div className='welcome-container'>
              Welcome to SMS Services...!
            </div>
          </div>
        ),
      },
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: 'login',
        element: <LogInCheck />
      },
      {
        path: 'createAccount',
        element: <CreateAccount />
      },
      {
        path: 'adminServices',
        element: <PrivateSecureRoute element={<AdminServices />} />
      },
      {
        path: "adminNewServices",
        element: <PrivateSecureRoute element={<AdminNewServices />} />
      },
      {
        path: "adminActiveServices",
        element: <PrivateSecureRoute element={<AdminActiveServices />} />
      },
      {
        path: "adminDetailServices",
        element: <PrivateSecureRoute element={<AdminDetailServices />} />
      },
      {
        path: 'addNewProduct',
        element: <PrivateSecureRoute element={<AddItem />} />
      },
      {
        path: 'addNewCustomer',
        element: <PrivateSecureRoute element={<AddNewCustomer />} />
      },
      {
        path: "addNewAgent",
        element: <PrivateSecureRoute element={<AddNewAgent />} />

      },
      {
        path: 'newOffers',
        element: <PrivateSecureRoute element={<OffersScreen />} />
      },
      {
        path: 'newPromoCode',
        element: <PrivateSecureRoute element={<PromoCodeForm />} />
      },
      {
        path: 'offersList',
        element: <PrivateSecureRoute element={<OffersList />} />
      },
      {
        path: 'offerDetails',
        element: <PrivateSecureRoute element={<OfferDetails />} />
      },
      {
        path: 'promoCodeList',
        element: <PrivateSecureRoute element={<PromoCodeList />} />
      },
      {
        path: 'viewAllItems',
        element: <PrivateSecureRoute element={<ViewAllItems />} />
      },
      {
        path: 'activeAgents',
        element: <PrivateSecureRoute element={<ActiveAgents />} />
      },
      {
        path: 'activeOrdersList',
        element: <PrivateSecureRoute element={<ActiveOrdersList />} />
      },
      {
        path: 'returnOrdersList',
        element: <PrivateSecureRoute element={<ReturnOrdersList />} />
      },





      {
        path: 'orderFullDetails',
        element: <PrivateSecureRoute element={<OrderFullDetails />} />
      },
      {
        path: 'orderHistory',
        element: <PrivateSecureRoute element={<CustomerOrderHistory />} />
      },
      {
        path: 'allAgentsList',
        element: <PrivateSecureRoute element={<AllAgentsList />} />
      },
      {
        path: 'totalOrdersList',
        element: <PrivateSecureRoute element={<TotalOrdersList />} />
      },

      {
        path: "allCustomersList",
        element: <PrivateSecureRoute element={<AllCustomersList />} />
      },
      {
        path: "customerFullDetails",
        element: <PrivateSecureRoute element={<CustomerFullDetails />} />
      },
      // {
      //   path: 'addToCart',
      //   element: <PrivateSecureRoute element={<AddToCart />} />
      // },
      {
        path: 'contactUs',
        element: <ContactUs />
      },
      {
        path: 'aboutUs',
        element: <AboutUs />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <AxiosInterceptorProvider>
          <RouterProvider router={router} />
        </AxiosInterceptorProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;
