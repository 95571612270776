import { Button, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton";

function AdminActiveServices(){

    const navigate = useNavigate();

    return(
        <>
            <div className="d-flex justify-content-center" style={{marginTop:'30px'}}>
            <Container>
            <Col>
                <Button variant="warning" className="indent-button" onClick={() => (navigate('/activeOrdersList'))}>Active Orders</Button>
                <Button variant="warning" className="indent-button" onClick={() => (navigate('/returnOrdersList'))}>Manage Returns</Button>

                <Button variant="danger" className="indent-button" onClick={() => (navigate('/viewAllItems'))}>Manage Stock</Button>                
                <Button variant="info" className="indent-button" onClick={() => (navigate('/activeAgents'))}>Agents Tasks</Button>   
                <Button variant="warning" className="indent-button" onClick={() => (navigate('/offersList'))}>Update Offers</Button>                             
                <Button variant="warning" className="indent-button" onClick={() => (navigate('/promoCodeList'))}>Update Promo Codes</Button>                             
            </Col>
            </Container>
            </div>
            <div className="indent-container">
              <BackButton />
            </div>
        </>
    );

}

export default AdminActiveServices;