import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import ApplicationNavBar from './NavBar.js';
import SideBars from './SideBar.js';
import './Layout.css';

function Header() {
  return (
    <>
      <ApplicationNavBar />
      <SideBars />
    </>
  );
}

function Layout() {
  return (
    <div>
      <Header />
      <main>
      <div >
        <Container fluid>
          <Row>
             <Col >
              <Outlet />
              <div className='footer-layout'>
              </div>
            </Col>
          </Row>
        </Container>
        </div>
      </main>
    </div>
  );
}

export default Layout;
