import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { Button, Col, Container, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import BackButton from "./BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import MapComponent from "./MapsComponents/MapComp";
import axiosAPIinstance from "../config/axiosAPIinstance";

function ActiveOrdersList() {

    const location = useLocation();
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [orderRef, setOrderRef] = useState('');
    const [status, setStatus] = useState('Processing');
    const [showAgentModal, setShowAgentModal] = useState(false);
    const [agentId, setAgentId] = useState('');
    const [showPaymentModel, setShowPaymentModel] = useState(false);
    const [paymentMode, setPaymentMode] = useState('');
    const [payment, setPayment] = useState('Pending');
    const [paymentValue, setPaymentValue] = useState(0);
    const [routeMap, setRoutMap] = useState(false);

    const orderStatusList = ['Shipping', 'Delivered', 'Closed', 'Canceled'];
    const modeOfPayments = ['Cash', 'PhonePe', 'Gpay', 'AmazonPay', 'PayTM', 'Others']
    const paymentStatusList = ['Pending', 'Paid', 'Defaulter']

    const getOrdersList = useCallback(async () => {
        try {
            const path = location.state?.id ? `/${location.state.id}` : '';
            const response = await axiosAPIinstance.get(`/order/getAllPendingOrders${path}`, { secure: true });
            console.log('response is', response.data);
            setOrders(response.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    }, [location.state]);

    useEffect(() => {
        getOrdersList();
    }, [getOrdersList]);

    const viewOrderDetails = (orderId, customerId, orderDetailsList) => {
        const filteredOrderDetailsList = orderDetailsList.filter(item => !item.returnRequested);
        const billType = 'Invoice';
        navigate('/orderFullDetails', { state: { orderId, customerId, filteredOrderDetailsList, billType } });
    };

    const handleClose = () => {
        setShowModal(false);
        setShowPaymentModel(false);
        setPayment('Pending');
    };

    const handleOrderRef = (id) => {
        setOrderRef(id);
        setShowModal(true);
    };

    const updatePaymentStatus = (id) => {
        setOrderRef(id);
        setShowPaymentModel(true);
    }

    function updatePaymentStatusType(val) {

        if (val !== 'Paid') {
            setPaymentMode('');
        }
        setPayment(val);
    }

    const handleUpdateOrderStatus = async () => {
        try {
            const response = await axiosAPIinstance.put('/order/updateDeliveryStatus',
                { orderId: orderRef, orderStatus: status },
                { secure: true }
            );
            if (response.data) {
                getOrdersList();
                setStatus(orderStatusList[0]);
            }
        } catch (error) {
            console.error("Error updating order status:", error);
        } finally {
            setShowModal(false);
        }
    };

    const assignOrderTo = (id) => {
        setOrderRef(id);
        setShowAgentModal(true);
    };

    const handleUpdateAgentId = async () => {
        try {
            const response = await axiosAPIinstance.put('/order/updateDeliveryAgent',
                { orderId: orderRef, deliveryAgent: agentId },
                { secure: true }
            );
            if (response.data) {
                getOrdersList();
            }
        } catch (error) {
            console.error("Error updating delivery agent:", error);
        } finally {
            setShowAgentModal(false);
        }
    };

    const handlePaymentUpdates = async () => {
        try {
            const paymentUpdateResponse = await axiosAPIinstance.put('/order/updatePaymentStatus',
                { orderId: orderRef, paymetType: paymentMode, paymentStatus: payment, amount: paymentValue },
                { secure: true }
            )
            console.log(paymentUpdateResponse.data);
        } catch (error) {
            console.log('Error in Updating Payment Status:' + error);
        }
        getOrdersList();
        setShowPaymentModel(false);
    }

    return (
        <div style={{ marginTop: '50px' }}>

            {location.state?.id && routeMap ?
                <div className="divFlexSpan" style={{ marginLeft: "0.5vw", marginRight: "0.5vw", marginBottom: "0.5vw" }}>
                    <MapComponent />
                </div>
                : ''}
            <Container className="w-100">
                {loading ? (
                    <Row className="justify-content-center">
                        <Spinner animation="border" role="status" variant="success" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Row>
                ) : (

                    <>
                        {orders.length > 0 ?
                            <Row className="d-grid gap-2">
                                {orders.map((order) => (
                                    <Col key={order.orderId}>
                                        <Row>
                                            <Col xl={1} md={1}>
                                                <Button variant="success" m={2}>ID=<strong>{order.orderId}</strong></Button>
                                            </Col>
                                            <Col>
                                                <Button className="w-100 custom-margin" variant="success" onClick={() => viewOrderDetails(order.orderId, order.customerId, order.orderDetails)}>
                                                    {order.customerId}
                                                </Button>
                                            </Col>
                                            <Col xl={2} md={2}>
                                                <Button className="w-100 custom-margin"
                                                    variant={order.orderStatus === 'Created' ? 'success' : (order.orderStatus === 'Confirmed' ? 'outline-primary' : 'outline-warning')}
                                                    onClick={() => handleOrderRef(order.orderId)}>
                                                    <strong>{order.orderStatus}</strong>
                                                </Button>
                                            </Col>

                                            <Col xl={1} md={1}>
                                                <Button className="w-100 custom-margin" variant="outline-secondary" onClick={() => updatePaymentStatus(order.orderId)}>
                                                    {order.paymentStatus || 'Pending'}
                                                </Button>
                                            </Col>
                                            <Col xl={2} md={2}>
                                                <Button className="w-100 custom-margin" style={order.deliveryAgent ? {} : { color: 'red', fontWeight: 'bold' }} variant="outline-secondary" onClick={() => assignOrderTo(order.orderId)}>
                                                    {order.deliveryAgent || 'UnAssigned'}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                            :
                            <div className="divFlexSpan">
                                <p><strong> No Pending Orders Found </strong></p>
                            </div>
                        }
                    </>
                )}
            </Container>

            <div className="divFlexSpan">
                <BackButton />
                {location.state?.id &&
                    <Button className='backButton-indent'
                        onClick={() => setRoutMap(!routeMap)}> {routeMap ? 'Hide Map' : 'Get Route'}</Button>
                }
            </div>



            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Order Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formOrderStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Select aria-label="Order Status Select" onChange={(e) => setStatus(e.target.value)}>
                                {orderStatusList.map(status => (
                                    <option value={status} key={status}>{status}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdateOrderStatus}>
                        Update Status
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showPaymentModel} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formPaymentStatus">

                            <Form.Label>Payment Status</Form.Label>
                            <Form.Select aria-label="Payment Status Select" onChange={(e) => updatePaymentStatusType(e.target.value)}>
                                {paymentStatusList.map(Pstats => (
                                    <option value={Pstats} key={Pstats}>{Pstats}</option>
                                ))}
                            </Form.Select>

                            {payment === 'Paid' ?
                                <>
                                    <Form.Label>Payment Mode</Form.Label>
                                    <Form.Select aria-label="Payment Status Select" onChange={(e) => setPaymentMode(e.target.value)}>
                                        {modeOfPayments.map(Ptype => (
                                            <option value={Ptype} key={Ptype}>{Ptype}</option>
                                        ))}
                                    </Form.Select>

                                    <Form.Label>Amount</Form.Label>
                                    <InputGroup className="mb-3" />
                                    <Form.Control
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        onChange={(e) => setPaymentValue(e.target.value)}
                                    />
                                </>
                                :
                                ''
                            }

                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handlePaymentUpdates}>
                        Update Payment
                    </Button>
                </Modal.Footer>
            </Modal>



            <Modal show={showAgentModal} onHide={() => setShowAgentModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Order To</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formAgentId">
                            <Form.Label>Agent Name/ID</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Agent Name or ID"
                                value={agentId}
                                onChange={(e) => setAgentId(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAgentModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleUpdateAgentId}>
                        Update Details
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ActiveOrdersList;
