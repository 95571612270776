import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { GrLogin, GrLogout } from "react-icons/gr";
import UnversalSpinner from './CommonUtilites/UniversalSpinner';
import axiosAPIinstance from '../config/axiosAPIinstance';
import { UserContext } from '../context/UserContext';

const LoginForm = () => {

  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const [connectionError, setConnectionError] = useState('');
  const [loginData, setLoginData] = useState({
    mobileNumber: '',
    passwordorPin: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };
  const handleNewAccountClick = () => {
    navigate('/createAccount');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setAccountNotFound(false);
    setConnectionError('');

    try {
      const response = await axiosAPIinstance.post('/user/login', loginData);
      if (response.status === 200 && response.data) {
        const { token, userDTO, authenticated } = response.data;
        login(token, userDTO, authenticated, userDTO.mobileNumber);
        setLoading(false);
      } else {
        setLoading(false);
        setAccountNotFound(true);
      }
    } catch (error) {
      setLoading(false);
      setConnectionError('Backend Error, please try again later.');
      console.error('Error during login:', error);
    }
  };

  return (
    <div>

      <Form className='login-container-frame'>
        <Form.Group className="mb-3" >
          <Form.Label>Login ID</Form.Label>
          <Form.Control md={10}
            type="text"
            name="mobileNumber"
            placeholder="Enter mobile number"
            value={loginData.mobileNumber}
            onChange={handleInputChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type={showPass ? "text" : "password"}
            name="passwordorPin"
            placeholder="Password"
            value={loginData.passwordorPin}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Show Password" onClick={() => setShowPass(!showPass)} />

          <Form.Text className="text-muted">
            {loading && !accountNotFound ? <UnversalSpinner /> : <> </>}
            {accountNotFound ?
              <div className='error-msg'>Invalid Login details</div> :
              <>
                {connectionError != null ? <
                  div className='error-msg'>{connectionError}</div> :
                  <> </>}
              </>
            }
          </Form.Text>

        </Form.Group>

        <Button variant="primary" type="submit" onClick={handleLogin} disabled={loading}>
          <GrLogin /> Login
        </Button>

        {accountNotFound ?
          <Button variant='primary' className='custom-indent-space'>Forgot Password</Button> : ''}

        <Button variant="primary" className='custom-indent-space' onClick={handleNewAccountClick}>
          <GrLogout /> Create Account
        </Button>
      </Form>
    </div>
  );
}

export default LoginForm;