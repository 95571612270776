import axios from 'axios';
import { useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';

// Create Axios instance
const axiosAPIinstance = axios.create({
    baseURL: 'http://13.233.178.111:8080',
});

// Setup Interceptors
const setupInterceptors = (logout) => {
    // Request Interceptor
    axiosAPIinstance.interceptors.request.use(
        async (config) => {
            try {
                const token = localStorage.getItem('jwtAuthToken');
                const isSecure = config?.secure || false;

                if (isSecure && token) {
                    const decodedToken = decodeToken(token);
                    const currentTime = Date.now() / 1000;

                    if (decodedToken.exp < currentTime) {
                        console.warn('Token expired, logging out.');
                        logout();
                        return Promise.reject(new Error('Token expired'));
                    }

                    config.headers.Authorization = `Bearer ${token}`;
                }
            } catch (error) {
                console.error('Request interceptor error:', error);
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Response Interceptor
    axiosAPIinstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.status === 401) {
                console.warn('Unauthorized access, logging out.');
                logout();
            }
            return Promise.reject(error);
        }
    );
};

// Token Decoding Helper Function
const decodeToken = (token) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Error decoding token manually:', error);
        throw new Error('Invalid token');
    }
};

// Axios Interceptor Provider
export const AxiosInterceptorProvider = ({ children }) => {
    const { logout } = useContext(UserContext);

    useEffect(() => {
        setupInterceptors(logout);
    }, [logout]);

    return children;
};

export default axiosAPIinstance;
