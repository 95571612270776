import { useState } from "react";
import axios from "axios";
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import BackButton from "../BackButton";
import axiosAPIinstance from "../../config/axiosAPIinstance";

function AddNewAgent() {
    const [response, setResponse] = useState("");
    const [editable, setEditable] = useState(false);

    const [agent, setAgent] = useState({
        name: "",
        contact: "",
        designation: "",
        salary: "",
        photo: null,
        dateOfJoining: new Date(),
        dateOfLeaving: null,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAgent({ ...agent, [name]: value });
    };

    const handleDateChange = (name, date) => {
        setAgent({ ...agent, [name]: date });
    };

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setAgent({ ...agent, [name]: files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEditable(true);
        try {
            const formData = new FormData();

            // Append all fields, including the file
            Object.keys(agent).forEach((key) => {
                if (key === 'photo' && agent[key]) {
                    formData.append(key, agent[key]); // Photo is handled as a file
                } else if (key !== 'photo') {
                    formData.append(key, agent[key]);
                }
            });

            const getResponse = await axiosAPIinstance.post(
                "/agent/createNewAgent",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    secure: true
                }
            );
            setResponse(getResponse.data);
            setAgent({
                name: "",
                contact: "",
                designation: "",
                salary: "",
                photo: null,
                dateOfJoining: new Date(),
                dateOfLeaving: null,
            })
        } catch (error) {
            console.error("Error during item addition:", error);
            setResponse("Error adding item. Please try again.");
        }
        setEditable(false);
    };

    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <div style={{ width: '50vw' }}>
                <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        <b>Create New Agent</b>
                    </Typography>

                    <TextField
                        label="Agent Name"
                        name="name"
                        variant="outlined"
                        value={agent.name}
                        onChange={handleInputChange}
                        fullWidth
                        required
                        margin="normal"
                    />
                    <TextField
                        label="Contact"
                        name="contact"
                        variant="outlined"
                        value={agent.contact}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Designation"
                        name="designation"
                        variant="outlined"
                        value={agent.designation}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Salary (Ex: 10,000/-)"
                        name="salary"
                        variant="outlined"
                        value={agent.salary}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />

                    <Typography variant="body1" gutterBottom>
                        Photo/Image [Max 10MB]
                    </Typography>
                    <input
                        type="file"
                        name="photo"
                        accept="image/*"
                        required
                        onChange={handleFileChange}
                        aria-label="Upload Image"
                        style={{ marginBottom: "16px" }}
                    />

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date of Joining"
                            value={agent.dateOfJoining}
                            onChange={(date) => handleDateChange("dateOfJoining", date)}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth margin="normal" />
                            )}
                        />
                        <DatePicker
                            label="Leaving Date"
                            value={agent.dateOfLeaving}
                            onChange={(date) => handleDateChange("dateOfLeaving", date)}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth margin="normal" />
                            )}
                        />
                    </LocalizationProvider>

                    {response && (
                        <Typography color="green" sx={{ mt: 2 }}>
                            <b>{response}</b>
                        </Typography>
                    )}
                    {editable && (
                        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={editable}
                        fullWidth
                    >
                        Add
                    </Button>
                    <>
                        <BackButton />
                    </>
                </Box>
            </div>
        </div>
    );
}

export default AddNewAgent;
