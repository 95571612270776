import { Button, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BackButton from "../BackButton";

function AdminNewServices(){

    const navigate = useNavigate();

    return(
        <>
        <div className="d-flex justify-content-center" style={{marginTop:'30px'}}>
        <Container>
        <Col>
            <Button variant="secondary" className="indent-button" onClick={() => (navigate('/addNewProduct'))}>New Product</Button>
            <Button variant="info" className="indent-button" onClick={() => (navigate('/addNewAgent'))}>New Agent</Button>
            <Button variant="success" className="indent-button" onClick={() => (navigate('/addNewCustomer'))}>New Customer</Button>           
            <Button variant="warning" className="indent-button" onClick={() => (navigate('/newOffers'))}>New Offers Screen</Button>
            <Button variant="warning" className="indent-button" onClick={() => (navigate('/newPromoCode'))}>Add New PromoCode</Button>
        </Col>
        </Container>
        </div>
        <div className="indent-container">
            <BackButton />
        </div>
        </>
    );

}

export default AdminNewServices;