import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, FormControlLabel, Checkbox, Grid, CircularProgress } from '@mui/material';
import axiosAPIinstance from '../../config/axiosAPIinstance';

const PromoCodeForm = () => {

    const [promoCode, setPromoCode] = useState({
        code: '',
        discountValue: 0,
        active: false,
        expirationDate: '',
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPromoCode({
            ...promoCode,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Input validation
        if (!promoCode.code || !promoCode.discountValue || !promoCode.expirationDate) {
          setError('Please fill out all fields.');
          return;
        }
        setLoading(true);
        setError(null);
        console.log('Submitting promo code:', promoCode);
        try {
          const response = await axiosAPIinstance.post('/offers/createPromoCode', promoCode, {secure:true});
          if (response.data) {
            setLoading(false);
            alert('Promo code saved successfully!');
            setPromoCode({
              code: '',
              discountValue: '',
              expirationDate: '',
              active: false,
            });
          } else {
            setLoading(false);
            setError('Failed to save promo code.');
          }
        } catch (error) {
          setLoading(false);
          console.error('Error saving promo code:', error);
          setError('Error saving promo code. Please try again.');
        }
      };

    if (loading) {
        return <div style={{ margin: '10rem 25rem', fontSize: '24px', fontWeight: 'bold' }}><CircularProgress /></div>;
    }

    return (
        <div style={{ margin: '5rem 25rem' }}>
            <h3>New Promo Code</h3>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Promo Code"
                            name="code"
                            value={promoCode.code}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Discount Value"
                            name="discountValue"
                            type="number"
                            value={promoCode.discountValue}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Expiration Date"
                            name="expirationDate"
                            type="datetime-local"
                            value={promoCode.expirationDate}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={promoCode.active}
                                    onChange={handleChange}
                                    name="active"
                                />
                            }
                            label="Is Active?"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
};

export default PromoCodeForm;
