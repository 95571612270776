import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";
import axiosAPIinstance from "../config/axiosAPIinstance";
import NvDataTable from "../components/NVDataTable";
import UniversalSpinner from "./CommonUtilites/UniversalSpinner";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from "recharts";

function TotalOrdersList() {
    const [orders, setOrders] = useState([]);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const headers = [
        "Order ID",
        "Order Date",
        "Customer ID",
        "Status",
        "Payment Type",
        "Payment Mode",
        "Agent",
    ];

    const viewOrderDetails = (row) => {
        const { orderId, customerId, orderDetails } = row;
        const filteredOrderDetailsList = orderDetails.filter(
            (item) => !item.returnRequested
        );
        const billType = "Bill";
        navigate("/orderFullDetails", {
            state: { orderId, customerId, filteredOrderDetailsList, billType },
        });
    };

    useEffect(() => {
        const getOrdersList = async () => {
            try {
                const response = await axiosAPIinstance.get("/order/getAllOrders", {
                    secure: true,
                });
                if (response.data) {
                    setOrders(
                        response.data.reverse().map((order) => ({
                            orderId: order.orderId,
                            orderDate: order.orderCreatedTime
                                .substring(0, 10)
                                .replaceAll("-", "/"),
                            customerId: order.customerId,
                            status: order.orderStatus,
                            paymentType: order.paymentMode || "Cash",
                            paymentMode: order.paymentStatus || "Pending",
                            agent: order.deliveryAgent || "UnAssigned",
                            orderDetails: order.orderDetails,
                        }))
                    );
                }
            } catch (error) {
                console.error("Error fetching orders:", error);
            } finally {
                setLoading(false);
            }
        };
        getOrdersList();
    }, []);

    // Calculate aggregates like total orders, total quantity, and total price for a customer
    const calculateAggregates = (customerId) => {
        const customerOrders = orders.filter(order => order.customerId === customerId);
        const totalOrders = customerOrders.length;
        const totalQuantity = customerOrders.reduce((total, order) => total + order.orderDetails.reduce((sum, item) => sum + item.productOrderedQuantity, 0), 0);
        const totalPrice = customerOrders.reduce((total, order) => total + order.orderDetails.reduce((sum, item) => sum + item.sellingPrice, 0), 0);
        const paymentStatus = customerOrders.some(order => order.paymentMode === 'Paid') ? 'Paid' : 'Pending';
        const deliveryAgent = customerOrders.length > 0 ? customerOrders[0].agent : "UnAssigned";

        return { totalOrders, totalQuantity, totalPrice, paymentStatus, deliveryAgent };
    };

    return (
        <Container style={{ margin: '0px' , marginTop: '5vh' }}>
            <Row>
                <Col md={10}>
                    {loading ? (
                        <UniversalSpinner />
                    ) : (
                        <NvDataTable
                            headers={headers}
                            data={orders}
                            onRowClick={viewOrderDetails}
                            onRowHover={(row) => setHoveredRow(row)} // Update hovered row
                        />
                    )}
                </Col>
                <Col md={2}>
                    {hoveredRow ? (
                        <div>
                            <h6><strong><u>Summary for Customer:-</u></strong> {hoveredRow.customerId}</h6>
                            <div>
                                <strong>Total Orders:</strong> {calculateAggregates(hoveredRow.customerId).totalOrders}
                            </div>
                            <div>
                                <strong>Total Quantity:</strong> {calculateAggregates(hoveredRow.customerId).totalQuantity}
                            </div>
                            <div>
                                <strong>Total Price:</strong> {(calculateAggregates(hoveredRow.customerId).totalPrice).toFixed(2)}
                            </div>
                            <div>
                                <strong>Payment Status:</strong> {calculateAggregates(hoveredRow.customerId).paymentStatus}
                            </div>
                            <div>
                                <strong>Delivery Agent:</strong> {calculateAggregates(hoveredRow.customerId).deliveryAgent}
                            </div>

                            <LineChart
                                width={350}
                                height={250}
                                data={orders.filter(order => order.customerId === hoveredRow.customerId).map((order, index) => ({
                                    name: `Order ${order.orderId}`,
                                    quantity: order.orderDetails.reduce((total, item) => total + item.productOrderedQuantity, 0),
                                    price: order.orderDetails.reduce((total, item) => total + item.productPrice, 0),
                                }))}
                                margin={{ top: 20, right: 20, bottom: 10, left: 0 }}
                            >
                                <CartesianGrid stroke="#ccc" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="quantity" stroke="#007bff" />
                                <Line type="monotone" dataKey="price" stroke="#82ca9d" />
                            </LineChart>
                        </div>
                    ) : (
                        <div style={{ textAlign: "center", marginTop: "50px" }}>
                            <p>Hover over a row to see the graph and summary</p>
                        </div>
                    )}
                </Col>
            </Row>
            <div className="divFlexSpan">
                <BackButton />
            </div>
        </Container>
    );
}

export default TotalOrdersList;
