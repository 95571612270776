// import './offers-screen.css';
import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Grid,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    DialogTitle,
} from '@mui/material';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { format } from 'date-fns';
import UnversalSpinner from '../CommonUtilites/UniversalSpinner';
import axiosAPIinstance from '../../config/axiosAPIinstance';

const OfferDetails = ({ item, isEditMode, onClose, onSave }) => {

    const [responseData, setResponseData] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [offerItems, setOfferItems] = useState(item);
    const [response, setResponse] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
    const [imgError, setImgError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const formatFloat = (value) => { return parseFloat(value).toFixed(2); };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setOfferItems({ ...offerItems, [name]: value });
        console.log("handleInputChange", name, value)
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setOfferItems({ ...offerItems, image: file });
        setImagePreview(URL.createObjectURL(file));
        console.log("handleFileChange", e.target.files[0])
        setImgError(true);
    };

    const handleAdditionalFilesChange = (e) => {
        const files = Array.from(e.target.files);
        setOfferItems({ ...offerItems, additionalImages: files });
        setAdditionalImagePreviews(files.map((file) => URL.createObjectURL(file)));
        console.log("handleAdditionalFilesChange", e.target.files)
        setImgError(true);
    };

    const handleDateChange = (name, date) => {
        const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");
        setOfferItems({ ...offerItems, [name]: formattedDate });
    };

    const handleSave = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        Object.keys(offerItems).forEach((key) => {
            if (offerItems[key] instanceof File) {
                // Append the file and images
                formData.append(key, offerItems[key]);
            } else if (Array.isArray(offerItems[key])) {
                // Append additional images
                offerItems[key].forEach((file) => {
                    if (file instanceof File) {
                        formData.append(key, file);
                    } else {
                        formData.append(key, JSON.stringify(file));
                    }
                });
            } else {
                // Append other fields as string values
                formData.append(key, offerItems[key]);
            }
        });

        try {
            setLoading(true);
            const response = await axiosAPIinstance.post('/offers/updateOfferDetails', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
                secure : true
            });
            setResponse(response.data);
            setLoading(false);
            setOpenModal(true);
            setOfferItems({
                offerTitle: '',
                description: '',
                image: null,
                productNames: '',
                brandNames: '',
                categoryNames: '',
                tags: '',
                active: false,
                additionalImages: [],
                startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
                endDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
            });

            setImagePreview(null);
            setAdditionalImagePreviews([]);
        } catch (error) {
            console.error('Error adding offer:', error);
            setResponse('Error adding offer. Please try again.');
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        onSave(responseData);
    };

    useEffect(() => {
    }, [offerItems])

    return (
        <>
            {loading ? (
                <div style={{ marginTop: '10px' }}>
                    <UnversalSpinner />
                </div>
            ) : (
                <>
                    <Dialog open={Boolean(item)} onClose={onClose} maxWidth="md" fullWidth>
                        <DialogTitle>{isEditMode ? 'Edit Offer Details' : 'View Offer Details'}</DialogTitle>
                        <DialogContent dividers>
                            <form onSubmit={handleSave}>
                                <Grid container spacing={2} margin={1} width={'95%'}>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="offerTitle"
                                            label="Offer Title"
                                            fullWidth
                                            required
                                            disabled={!isEditMode}
                                            value={offerItems.offerTitle}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="productNames"
                                            label="Product Names"
                                            fullWidth
                                            required
                                            disabled={!isEditMode}
                                            value={offerItems.productNames}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="brandNames"
                                            label="Brand Names"
                                            fullWidth
                                            required
                                            disabled={!isEditMode}
                                            value={offerItems.brandNames}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="categoryNames"
                                            label="Category Names"
                                            fullWidth
                                            required
                                            disabled={!isEditMode}
                                            value={offerItems.categoryNames}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="tags"
                                            label="Tags"
                                            fullWidth
                                            disabled={!isEditMode}
                                            value={offerItems.tags}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                label="Start Date and Time"
                                                value={offerItems.startDate}
                                                required
                                                disabled={!isEditMode}
                                                onChange={(date) => handleDateChange('startDate', date)}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                label="End Date"
                                                value={offerItems.endDate}
                                                required
                                                disabled={!isEditMode}
                                                onChange={(date) => handleDateChange('endDate', date)}
                                                renderInput={(params) => <TextField fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <InputLabel id="isActive-status-label">IsActive</InputLabel>
                                        <Select
                                            id="isActive-status-select"
                                            labelId="Is Active"
                                            name="active"
                                            disabled={!isEditMode}
                                            value={offerItems.active}
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value={true}>Yes</MenuItem>
                                            <MenuItem value={false}>No</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="description"
                                            label="Description"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            disabled={!isEditMode}
                                            value={offerItems.description}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && offerItems.image ? '' : 'red', margin: '1rem 0rem' }} disabled={!isEditMode}>
                                            Upload Image
                                            <input type="file" hidden name="image" onChange={handleFileChange} required />
                                        </Button>
                                    </Grid>
                                    {imagePreview && (
                                        <Grid item xs={12}>
                                            <img
                                                src={imagePreview}
                                                alt="Preview"
                                                style={{ width: '250px', maxHeight: '250px', objectFit: 'cover', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" component="label" fullWidth style={{ backgroundColor: imgError && offerItems.additionalImages.length > 0 ? '' : 'red', margin: '1rem 0rem' }} disabled={!isEditMode}>
                                            Upload Additional Images
                                            <input type="file" hidden multiple name="additionalImages" onChange={handleAdditionalFilesChange} />
                                        </Button>
                                    </Grid>
                                    {additionalImagePreviews.length > 0 && (
                                        <Grid item xs={12}>
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {additionalImagePreviews.map((image, index) => (
                                                    <img
                                                        key={index}
                                                        src={image}
                                                        alt="Additional Preview"
                                                        style={{ width: '100px', height: '100px', objectFit: 'cover', margin: '5px' }}
                                                    />
                                                ))}
                                            </div>
                                        </Grid>
                                    )}

                                </Grid>
                                {loading && <UnversalSpinner />}
                                {/* <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Submit
                                </Button> */}
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            {isEditMode && (
                                <Button onClick={handleSave} color="primary" variant="contained">
                                    {loading && <UnversalSpinner />}
                                    Save
                                </Button>
                            )}
                        </DialogActions>
                    </Dialog>

                    {/* Modal to display response */}
                    <Dialog open={openModal} onClose={handleCloseModal}>
                        <DialogContent>
                            <Typography variant="h6" align="center" color="textSecondary">
                                Update Completed
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )
            }
        </>

    );
};

export default OfferDetails;