import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Table, Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { GrPrint } from "react-icons/gr";
import UnversalSpinner from "./CommonUtilites/UniversalSpinner";
import BackButton from "./BackButton";
import jsPDF from "jspdf";
import "jspdf-autotable";

function OrderFullDetails() {

    let sno = 1;
    const [loading, setLoading] = useState(false);
    const [orderDetailsList, setOrderDetailsList] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [totalgst, setTotalgst] = useState(0);
    const [totaldiscountPrice, setTotaldiscountPrice] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    const { orderId, customerId, filteredOrderDetailsList, billType } = location.state || {};

    useEffect(() => {
        if (filteredOrderDetailsList) {
            setLoading(true);
            const getOrderDetailsList = async () => {
                // const response = await axios.get("http://13.233.178.111:8080/getOrderDetailsList", { params: { 'orderId': orderId } });
                console.log(filteredOrderDetailsList);
                setOrderDetailsList(filteredOrderDetailsList);
                setLoading(false);
            };
            getOrderDetailsList();
        }
    }, [filteredOrderDetailsList]);

    useEffect(() => {
        const totalQuantity = orderDetailsList.reduce((sum, item) => sum + item.productOrderedQuantity, 0);
        setTotalQuantity(totalQuantity);
    }, [orderDetailsList]);
    useEffect(() => {
        const totalCost = orderDetailsList.reduce((sum, item) => sum + (item.sellingPrice * item.productOrderedQuantity), 0);
        setTotalCost(totalCost.toFixed(2));
    }, [orderDetailsList]);
    useEffect(() => {
        const totalDisc = orderDetailsList.reduce((sum, item) => sum + (item.discountPrice * item.productOrderedQuantity), 0);
        setTotaldiscountPrice(totalDisc.toFixed(2));
    }, [orderDetailsList]);
    useEffect(() => {
        const gstTotal = orderDetailsList.reduce((sum, item) => sum + (item.gstPrice * item.productOrderedQuantity ), 0);
        setTotalgst(gstTotal.toFixed(2));
    }, [orderDetailsList]);

    const finalCost = (parseFloat(totalCost) + parseFloat(totalgst)).toFixed(2);

    const handlePrint = () => {
        const doc = new jsPDF();
    
        doc.setFontSize(12);
        doc.text("PresentingOneIndia", 105, 6, null, null, "center");
        doc.text("SMS Packaging Services", 105, 12, null, null, "center");
        doc.text(`${billType} BILL - ${orderId}`, 105, 18, null, null, "center");
    
        const now = new Date();
        doc.text(`Date: ${now.toLocaleString('en-GB',{hour12:true}).replace(',','')}`, 10, 30);
        doc.text("GST No: xxx xxx xxx xxx", 10, 36);
    
        doc.autoTable({
            startY: 45,
            head: [['S.NO', 'Item Name', 'GST', 'Quantity', 'Item Price', 'Discount Price', 'Total Cost']],
            body: orderDetailsList.map((o, index) => [
                { content: index + 1, styles: { halign: 'center' } },
                { content: o.productName.replaceAll(' ', ''), styles: { halign: 'left' } },
                { content: `${o.gstPrice}%`, styles: { halign: 'right' } },
                { content: o.productOrderedQuantity, styles: { halign: 'center' } },
                { content: o.sellingPrice.toFixed(2), styles: { halign: 'right' } },
                { content: (o.discountPrice).toFixed(2), styles: { halign: 'right' } },
                { content: (o.sellingPrice * o.productOrderedQuantity).toFixed(2), styles: { halign: 'right' } }
            ]),
            styles: { fontSize: 10 },
            theme: 'striped',
            headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // For better visibility
        });
    
        const finalCostText = `Grand Total: Rs. ${finalCost}/-`;
        doc.text(finalCostText, 105, doc.lastAutoTable.finalY + 10, null, null, "center");
    
        doc.save(`Invoice_${orderId}.pdf`);
    };
    

    return (
        <>
            <Container id="print-container" className="d-flex flex-column align-items-center" style={{marginTop:'5vh', verticalAlign:"center"}}>
                <p>Order ID: <b>{orderId}</b></p>
                <p>Customer ID: <b>{customerId}</b></p>
        
                <Table striped bordered hover responsive className="w-100">
                    <thead align="center">
                        <tr>
                            <th style={{width: '50px'}}>S.NO</th>
                            <th align="left">ProductName</th>
                            <th align="right">GST</th>
                            <th align="right">Quantity</th>
                            <th align="right">ItemPrice</th>
                            <th align="right">DiscountPrice</th>
                            <th align="right">TotalCost</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderDetailsList.map(
                            (o) => (
                                o.productOrderedQuantity > 0 ? (
                                    <tr key={o.itemId}>
                                        <td align="center">{sno++}</td>
                                        <td align="left">{(o.productName).replaceAll(' ', '')}</td>
                                        <td align="right">{o.gstPercent}%</td>
                                        <td align="right"><b>{o.productOrderedQuantity}</b></td>
                                        <td align="right">{(o.sellingPrice).toFixed(2)}</td>
                                        <td align="right">{(o.discountPrice).toFixed(2)}</td>
                                        <td align="right"><b>{(o.sellingPrice * o.productOrderedQuantity).toFixed(2)}</b></td>
                                    </tr>
                                ) : null
                            )
                        )}
                        <tr align="center">
                            <td colSpan={3}>Total</td>
                            <td ><b>{totalQuantity}</b></td>
                            <td align="right"><b>{ totalCost }</b></td>
                            <td align="right"><b>{totaldiscountPrice}</b></td>
                            <td align="right"><b>-</b></td>
                        </tr>
                        <tr align="center">
                            <td colSpan={5}><i><b>GST</b></i></td>
                            <td colSpan={2} align="right">Rs.<b>{totalgst}</b>/-</td>
                        </tr>
                        <tr>
                            <td colSpan={5}><center><b>Grand Total</b></center></td>
                            <td colSpan={2} align="right">Rs.<b>{finalCost}/-</b></td>
                        </tr>
                    </tbody>
                </Table>
            </Container>

            <div className="divFlexSpan">
                {loading && <UnversalSpinner />}
            </div>
            <div className="divFlexSpan">
                <BackButton />
                <Button variant="outline-secondary" className='indent-position' onClick={handlePrint}><GrPrint /> Print Bill</Button>
                <Button variant="outline-secondary" className='indent-position' onClick={() => navigate('/orderHistory')}>My Orders History</Button>
            </div>
        </>
    );
}

export default OrderFullDetails;
