import React from 'react';
import { Container } from 'react-bootstrap';
import LogInCheck from '../LogInCheck';

function Home() {
  return (
    
      <Container fluid>
        {/* <Row> 
          <Col style={{padding:'10px 0px', marginTop:'4rem'}}>
            <HomePageCarosel />
          </Col>
        </Row>
        <Row>
          <Col style={{padding:'10px 0px', marginTop:'4rem'}}>
            <HomePageContent />
          </Col>
        </Row> */}

        <LogInCheck />
      </Container>
  );
}

export default Home;
