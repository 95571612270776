import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

const PrivateSecureRoute = ({ element }) => {

  const { isAuthenticated } = useContext(UserContext);

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default PrivateSecureRoute;
